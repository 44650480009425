<template>
  <div class="custom_scrollbar">
    <ul>
     
      <li
        v-for="(job, index) in selected_warehouse_jobs_materials
          .filter(
            (e) =>
              e.ID == $parent.current_warehouse_date ||
              $parent.current_warehouse_date == -1
          )
          .sort(
            (a, b) =>
              $moment(a.departure_date).valueOf() -
              $moment(b.departure_date).valueOf()
          )"
        :key="index"
        @click="$parent.collapse_job('collapsed', job)"
        class="has-border pl-1 has-background-white mb-2 is-clickable"
      >
        <small v-scroll-to="'#wj' + job.ID">
          <div v-if="$parent.direction">
            <span
              v-if="job.departure_date"
              class="has-text-dark has-text-weight-bold"
            >
              {{ job.departure_date | customdate }}
            </span>
            <span v-else class="has-text-danger-dark has-text-weight-bold">
              {{ job.DayTimeOut | customdate }}
            </span>
            <span class="ml-2 has-text-grey-light">
              {{ job.Number }}
            </span>

            <span
              v-if="job.truck_jobs"
              class="is-pulled-right tag is-info is-light"
            >
              <span v-if="$parent.direction">
                {{ job.truck_jobs_completed }} / {{ job.truck_jobs.length }}
                <i class="fa fa-truck ml-2"></i>
              </span>
              <span v-else>
                {{ job.returning_jobs_completed }} /
                {{ job.returning_jobs.length }}
                <i class="fa fa-truck ml-2"></i>
              </span>
            </span>
          </div>
          <!--
							<div v-else class="has-text-dark has-text-weight-bold">
								{{job.DayTimeIn | customdate}} 
							</div>
							-->
        </small>
        <table class="table is-striped is-narrow left-materials-table mt-2">
          <thead>
            <tr>
              <td># <span class="has-text-weight-bold">Materials</span></td>

              <td class="has-text-centered">
                <i title="Total Requested" class="fas fa-xs fa-warehouse"></i>
              </td>
              <td class="has-text-centered" v-if="!$parent.direction">
                <i title="Expected Onsite" class="fas fa-xs fa-map-marker"></i>
              </td>
              <td class="has-text-centered">
                <i title="Planning" class="fas fa-xs fa-truck-loading"></i>
              </td>
              <td class="has-text-centered">
                <i
                  title="Loaded on Truck"
                  :class="[$parent.direction ? '' : '']"
                  class="fas fa-xs fa-truck"
                ></i>
              </td>
              <!-- <td class="has-text-centered">
                <i
                  :title="$parent.direction ? 'Departed' : 'Checked In'"
                  :class="[$parent.direction ? '' : '']"
                  class="fas fa-sm fa-check-circle"
                  style="color: #5c9744"
                ></i>
              </td> -->
            </tr>
          </thead>
          <tbody v-if="job.JobState.ID == 1">
            <tr
              class=""
              v-for="(material, index) in job.materials"
              :key="index"
              :draggable="isMaterialDraggable(material, job)"
              @dragstart="startDragMaterial(material)"
            >
              <td>
                <!-- <small>{{ index + 1 }}. </small> -->
                <small> {{ material.Caption }} </small>
              </td>
              <!--delivery total materials -->
              <td class="has-text-centered">{{ material.QtyWas }}</td>
              <!--return total materials -->
              <td class="has-text-centered" v-if="!$parent.direction">
                {{
                  material.LoadedAmount -
                  material.ReturnedAmount +
                  material.TransitPlannedAmount
                }}
                <br />
              </td>
              <!--delivery planned materials -->
              <td class="has-text-centered" v-if="$parent.direction">
                <span
                  v-if="material.Remains < 0"
                  class="is-flex is-align-items-center"
                >
                  <i
                    class="fas fa-xs fa-exclamation-triangle has-text-danger mr-1"
                  ></i>
                  <span>
                    {{ material.PlannedAmount }}
                  </span>
                </span>
                <span v-else>
                  {{ material.PlannedAmount }}
                </span>
              </td>
              <!--delivery loaded materials -->
              <td class="has-text-centered" v-if="$parent.direction">
                <span
                  v-if="material.Remains < 0"
                  class="is-flex is-align-items-center"
                >
                  <i
                    class="fas fa-xs fa-exclamation-triangle has-text-danger mr-1"
                  ></i>
                  <span>
                    {{ material.LoadedAmount }}
                  </span>
                </span>
                <span v-else>
                  {{ material.LoadedAmount }}
                </span>
              </td>
              <!--return planned materials -->
              <td v-if="!$parent.direction" class="has-text-centered">
                {{ material.PlannedAmountReturn }}
              </td>
              <!--return loaded materials -->
              <td v-if="!$parent.direction" class="has-text-centered">
                {{ material.ReturnedAmount }}
              </td>
              <!-- <td v-if="$parent.direction" class="has-text-centered">
                {{ material.DepartedAmount }}
              </td>
              <td v-else class="has-text-centered">
                {{ material.DepartedAmount }}
              </td> -->
            </tr>
          </tbody>
        </table>
      </li>
    </ul>
  </div>
</template>
<script>
import { EventBus } from "@/event-bus";
export default {
  props: ["selected_warehouse_jobs_materials"],
  methods: {
    startDragMaterial(material) {
      EventBus.$emit("set_current_material_drag", material);
    },
    isMaterialDraggable(material, job) {
      if (job.status_id != 3 && this.$parent.direction)
        return (
          (material.Qty > 0 ||
            material.Remains != 0 ||
            (material.Remains > 0 && job.status_id == 1)) &&
          job.truck_jobs &&
          job.truck_jobs.length > 0
        );
      else {
        // console.log('material', material)
        return (
          material.QtyWas > parseInt(material.ReturnedAmount) &&
          material.QtyWas > parseInt(material.PlannedAmountReturn)
        );
      }
    },
  },
  created() {
    // console.log('aba',
    //   this.selected_warehouse_jobs_materials
    //     .filter(
    //       (e) =>
    //         e.ID == this.$parent.current_warehouse_date ||
    //         this.$parent.current_warehouse_date == -1
    //     )
    //     .sort(
    //       (a, b) =>
    //         this.$moment(a.departure_date).valueOf() -
    //         this.$moment(b.departure_date).valueOf()
    //     )
    //     .map((e) => e.DayTimeOut)
    //     .filter((v, i, a) => a.indexOf(v) === i)
    //     .map((e) => {
    //       return {
    //         DayTimeOut: e,
    //         jobs: this.selected_warehouse_jobs_materials.filter(
    //           (f) => f.DayTimeOut == e
    //         ),
    //       };
    //     })
    // );
  },
};
</script>
